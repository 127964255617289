<template>
  <v-row
    justify="space-between"
    align="center"
    no-gutters
  >
    <v-col v-bind="{...contractColumnSizes}">
      <v-card-title class="pa-0">
        <b
          class="mr-1"
          v-text="contract.product.name"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="mr-1"
          v-html="localizedContract"
        />
        <span
          class="mr-1"
          v-html="localizedUsersCount"
        />
        <span
          class="mr-1"
          v-html="localizedExpiration"
        />
        <!-- eslint-enable vue/no-v-html -->
        <b class="mr-1">{{ contract.product.expiration | formatDate }}</b>
        <span v-if="contract.product.type === typeCloud">
          <span v-t="'contractCard.domain'" />
          <a
            target="_blank"
            rel="noreferrer"
            :href="'http://' + contract.product.domain"
          >
            {{ contract.product.domain }}
          </a>
        </span>
      </v-card-title>

      <v-card-subtitle class="px-0 pt-5 pb-sm-0 d-sm-none d-lg-block">
        <p class="mb-0">
          <span v-t="'contractCard.contractId'" />
          <b class="ml-1">{{ contract.id }}</b>
        </p>
      </v-card-subtitle>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="5"
      lg="3"
    >
      <slot />
    </v-col>
    <v-col
      cols="12"
      class="d-none d-sm-flex d-lg-none"
    >
      <v-card-subtitle class="px-0 pb-0 pt-2">
        <b
          class="mr-1"
          v-text="contract.product.name"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="mr-1"
          v-html="localizedContract"
        />
        <span
          class="mr-1"
          v-html="localizedUsersCount"
        />
        <span
          class="mr-1"
          v-html="localizedExpiration"
        />
        <b class="mr-1">{{ contract.product.expiration | formatDate }}</b>
        <span v-if="contract.product.type === typeCloud">
          <span v-t="'contractCard.domain'" />
          <a
            target="_blank"
            rel="noreferrer"
            :href="'http://' + contract.product.domain"
          >
            {{ contract.product.domain }}
          </a>
        </span>
      </v-card-subtitle>
    </v-col>
  </v-row>
</template>

<script>
import { TYPE_SERVER, TYPE_CLOUD } from '../../../constants/app'
import getLocalizedContractProductType from '../../../utils/getLocalizedContractProductType'

export default {
  props: {
    contract: {
      type: Object,
      required: true
    },
    hasButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      typeServer: TYPE_SERVER,
      typeCloud: TYPE_CLOUD
    }
  },
  computed: {
    contractColumnSizes () {
      return {
        cols: 12,
        ...this.hasButton
          ? { sm: 6, md: 7, lg: 8 }
          : {}
      }
    },
    localizedUsersCount () {
      return this.$tc('contractCard.forUsersCountUsers', this.contract.product.maxUsersCount)
    },
    localizedContract () {
      return getLocalizedContractProductType(this.contract)
    },
    localizedExpiration () {
      if (this.contract.product.type === TYPE_CLOUD) {
        return this.$t('contractCard.paidUntil')
      }

      if (this.contract.product.type === TYPE_SERVER) {
        return this.$t('contractCard.withUpdatesUntil')
      }

      return this.$t('contractCard.unknownType')
    }
  }
}
</script>
